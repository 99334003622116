.input--states, .flag-button--states {
  border: 1px solid rgba(0,0,0,0.23) !important;
}
.input--states:hover, .flag-button--states:hover, .input--states:hover + .flag-button--states {
  border: 1px solid #282828 !important;
}
.input--states:focus, .input--states:focus + .flag-button--states {
  border: 2px solid #FAAD1B !important;
}
.phone-error > .input--states,
.phone-error > .flag-button--states,
.phone-error > .input--states:hover + .flag-button--states  {
  border: 1px solid #FF515C !important;
}
.phone-error > .input--states:focus,
.phone-error > .input--states:focus + .flag-button--states {
  border: 2px solid #FF515C !important;
}
.country.highlight {
  margin: 0 !important;
  font-weight: 500 !important;
  background-color: #FFF1D6 !important;
}