.MuiDataGrid-main .MuiDataGrid-cell.dg-text-wrapper-cell > .MuiDataGrid-cellContent{
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-chip > .MuiDataGrid-cellContent{
    border-radius: 6px;
    background-color: #FFFFFF;
    text-transform: uppercase;
    border: 2px solid rgba(0, 215, 145, 0.7);
    padding: 1px 8px;
    font-weight: bold;
    font-size: 12px;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-chip.success > .MuiDataGrid-cellContent{
    border-color: #00D791;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-chip.error > .MuiDataGrid-cellContent{
    border-color: #FF515C;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-chip.warning > .MuiDataGrid-cellContent{
    border-color: #FAAD1B;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-chip.default > .MuiDataGrid-cellContent{
    border-color: #B0B1BB;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-empty-line > .MuiDataGrid-cellContent{
    color: #B0B1BB;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-percentage-cell > .MuiDataGrid-cellContent:after{
    content: '%';
    color: #B0B1BB;
    margin-left: 4px;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell > .MuiDataGrid-cellContent:before{
    content: '';
    color: #B0B1BB;
    margin-right: 4px;
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell.EUR > .MuiDataGrid-cellContent:before{
    content: '€';
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell.PLN > .MuiDataGrid-cellContent:before{
    content: 'zł';
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell.USD > .MuiDataGrid-cellContent:before{
    content: '$';
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell.GBP > .MuiDataGrid-cellContent:before{
    content: '£';
}

.MuiDataGrid-main .MuiDataGrid-cell.dg-currency-cell.SEK > .MuiDataGrid-cellContent:before{
    content: 'kr';
}

.offersDataGridRowState {
    position: absolute;
    top: -12px;
    left: -12px;
    bottom: -19px;
    border-left: 8px solid white;
}

.potentialBar > div:not(:empty) {
    text-align: center;
    border: 1px solid #DDE8F1;
    border-radius: 2px;
    width: 98px;
    line-height: 28px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.22px;
    color: #8B8B8D;
    position: relative;
    transform-style: preserve-3d;
}
.potentialBar > div:not(:empty)::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    z-index: -1;
    transform: translateZ(-1px);
    background-color: #DDF969;
}
.potentialBar.potential-0 > div:not(:empty)::before {
    right: 100%;
    background-color: transparent;
}
.potentialBar.potential-1 > div:not(:empty)::before {
    right: 75%;
    background-color: #FEFE69;
}
.potentialBar.potential-3 > div:not(:empty)::before {
    right: 25%;
    background-color: #A9F36A;
}
.potentialBar.potential-4 > div:not(:empty)::before {
    right: 0%;
    background-color: #57E86B;
}